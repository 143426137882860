// ---------------------------------------------
// ======---------------------------------======
// Text slider
// ======---------------------------------======
// ---------------------------------------------
const textSliders = document.querySelectorAll(".text-slider")
if (textSliders.length > 0) {
	textSliders.forEach(slider => {
		const slides = slider.querySelectorAll(".text-slider__slides > div")
		const prevBtn = slider.querySelector(
			'.text-slider__controls [data-control="prev"]'
		)
		const nextBtn = slider.querySelector(
			'.text-slider__controls [data-control="next"]'
		)
		const animationTime = parseInt(
			window
				.getComputedStyle(slider)
				.getPropertyValue("--ts-time")
				.replace("ms", "")
		)

		let currentSlide = 0
		slides[currentSlide].classList.add("js-active")

		function controlVisibility(state) {
			if (state === "disable") {
				prevBtn.setAttribute("disabled", true)
				nextBtn.setAttribute("disabled", true)
			} else {
				prevBtn.removeAttribute("disabled")
				nextBtn.removeAttribute("disabled")
			}
		}

		function changeSlide(startSlide, end) {
			controlVisibility("disable") // disable controls

			const endSlide =
				end < 0 ? slides.length - 1 : end >= slides.length ? 0 : end

			const transitionSlide = new Promise(resolve => {
				slides[startSlide].classList.add("js-out") // set fade out el
				slides[startSlide].classList.remove("js-active") // clear active

				setTimeout(() => {
					slides[endSlide].classList.add("js-in") // start new fade in
				}, animationTime / 2)

				setTimeout(() => {
					slides[startSlide].classList.remove("js-out") // clear fade out

					setTimeout(() => {
						slides[endSlide].classList.remove("js-in") // clear fade in
						slides[endSlide].classList.add("js-active") // set active
						resolve("done")
					}, animationTime)
				}, animationTime)
			})

			transitionSlide.then(() => {
				currentSlide = endSlide
				controlVisibility("enable") // enable controls
			})
		}

		function clickHandler(direction) {
			if (direction === "prev") {
				changeSlide(currentSlide, currentSlide - 1)
			}

			if (direction === "next") {
				changeSlide(currentSlide, currentSlide + 1)
			}
		}

		prevBtn.addEventListener("click", () => clickHandler("prev"))
		nextBtn.addEventListener("click", () => clickHandler("next"))
	})
}
