// ---------------------------------------------
// ======---------------------------------======
// Video modal
// ======---------------------------------======
// ---------------------------------------------
import "https://player.vimeo.com/api/player.js"

const videoModals = document.querySelectorAll("[data-vm-id]")
const overlay = document.querySelector(".overlay")

function closeModal(modal) {
	const iframe = modal.querySelector("iframe")
	const player = new Vimeo.Player(iframe)

	modal.close()
	player.pause()
}

if (videoModals.length > 0) {
	videoModals.forEach(modalBtn => {
		const modalId = modalBtn.getAttribute("data-vm-id")
		const modalDialog = document.querySelector(`#${modalId}`)
		const iframe = modalDialog.querySelector("iframe")

		modalBtn.addEventListener("click", e => {
			e.preventDefault()
			modalDialog.showModal()
		})

		modalDialog.querySelector("button").addEventListener("click", e => {
			e.preventDefault()
			closeModal(modalDialog)
		})

		modalDialog.addEventListener("click", e => {
			e.stopImmediatePropagation()
			closeModal(modalDialog)
		})
	})
}
