//===-----------------===
// about animation fill
//===-----------------===

const journeyAbout = document.querySelector(".cs-journey-about")
if (journeyAbout) {
	const path = document.querySelector(".journey-about-svg-path")
	if (path) {
		const length = path.getTotalLength()
		path.style.strokeDasharray = length
		path.style.strokeDashoffset = length

		const updateDrawing = progress => {
			const drawLength = length * progress

			gsap.set(path, {
				strokeDashoffset: length - drawLength,
			})
		}

		ScrollTrigger.create({
			trigger: journeyAbout,
			start: "top top",
			end: "bottom bottom",
			scrub: true,
			onUpdate: self => {
				updateDrawing(self.progress)
			},
		})
	}
}
