// ---------------------------------------------
// ======---------------------------------======
// Page navigation
// ======---------------------------------======
// ---------------------------------------------

const mainNav = document.querySelector("#primary-navigation")
if (mainNav) {
	// ====---------------====
	// Main nav toggles
	// ====---------------====
	const mainNavOpen = document.querySelector(".ph__nav__open")
	const mainNavClose = document.querySelector(".ph__nav__close")

	function openNav() {
		mainNavOpen.setAttribute("aria-expanded", true)
		mainNavClose.setAttribute("aria-expanded", true)
		mainNav.setAttribute("data-state", "open")
	}

	function closeNav() {
		mainNavOpen.setAttribute("aria-expanded", false)
		mainNavClose.setAttribute("aria-expanded", false)
		mainNav.setAttribute("data-state", "closed")
	}

	function toggleNav() {
		if (mainNav.getAttribute("data-state") == "closed") {
			openNav()
		} else {
			closeNav()
		}
	}

	mainNavOpen.addEventListener("click", event => {
		event.stopPropagation()
		toggleNav()
	})

	mainNavClose.addEventListener("click", event => {
		event.stopPropagation()
		toggleNav()
	})

	// ====---------------====
	// Dropdown toggles
	// ====---------------====
	const mainNavDrops = document.querySelectorAll(".pn-drop")

	function mainNavDropOpen(drop) {
		drop.setAttribute("data-state", "open")
		drop.querySelector(".pn-drop-link").setAttribute("aria-expanded", true)
	}

	function mainNavDropCloseAll() {
		mainNavDrops.forEach(drop => {
			drop.setAttribute("data-state", "closed")
			drop.querySelector(".pn-drop-link").setAttribute("aria-expanded", false)
		})
	}

	function mainNavDropToggle(drop) {
		if (drop.getAttribute("data-state") == "closed") {
			mainNavDropCloseAll()
			mainNavDropOpen(drop)
		} else {
			mainNavDropCloseAll()
		}
	}

	mainNavDrops.forEach(drop => {
		// ===------ Fix child elements firing parent ------===
		const dropDownChildren = drop.querySelectorAll(".pn-drop-sub a")
		dropDownChildren.forEach(child => {
			child.addEventListener("click", e => {
				child.classList.add("clicking")
				e.stopPropagation()
			})
		})

		// ===------ Mouse Enters the Drop list element ------===
		drop.addEventListener("mouseenter", e => {
			e.stopPropagation()

			if (window.outerWidth >= 768) {
				if (drop.getAttribute("data-state") == "closed") {
					mainNavDropCloseAll()
					mainNavDropOpen(drop)
				} else {
					mainNavDropCloseAll()
				}
			}
		})

		// ===------ Mouse Leaves the Drop list element ------===
		drop.addEventListener("mouseleave", e => {
			e.stopPropagation()
			if (drop.querySelector(".clicking")) {
				console.log("child being clicked")
				return false
			} else if (window.outerWidth >= 768) {
				mainNavDropCloseAll()
			}
		})

		// ===------ click event ------===
		drop.querySelector(".pn-drop-link").addEventListener("click", e => {
			if (window.outerWidth <= 767) {
				e.stopPropagation()
				e.preventDefault()

				mainNavDropToggle(drop)
			}
		})
	})
}
