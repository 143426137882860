//---------------------------------
//===---------------------------===
// Rotating list
//===---------------------------===
//---------------------------------
if (document.querySelector(".rl-controls")) {
	const rotatingList = document.querySelectorAll(".rl__content li"),
		prevBtn = document.querySelector(".rl-control--prev"),
		nextBtn = document.querySelector(".rl-control--next"),
		images = document.querySelectorAll(".rl__images > img"),
		paginationBubbles = document.querySelector(".rl-control__pagination")

	let currentIndex = 0
	const totalItems = rotatingList.length

	rotatingList[currentIndex].classList.add("js-active")

	rotatingList.forEach((item, index) => {
		const bubble = document.createElement("span"),
			itemDataID = rotatingList[index].getAttribute("data-id")
		bubble.classList.add("rl-control--circle")

		bubble.setAttribute("data-id", itemDataID)

		if (item.classList.contains("js-active")) {
			bubble.classList.add("js-active")
		}

		paginationBubbles.appendChild(bubble)
	})

	function updateActiveItem(index) {
		const pagination = document.querySelectorAll(".rl-control--circle")

		rotatingList.forEach(item => item.classList.remove("js-active"))
		images.forEach(image => image.classList.remove("js-active"))
		pagination.forEach(item => item.classList.remove("js-active"))

		rotatingList[index].classList.add("js-active")

		const activeItem = rotatingList[index],
			dataID = activeItem.getAttribute("data-id"),
			activeImage = document.querySelector(
				`.rl__images > img[data-id="${dataID}"]`
			),
			activePagination = document.querySelector(
				`.rl-control--circle[data-id="${dataID}"]`
			)
		activePagination.classList.add("js-active")
		activeImage.classList.add("js-active")
	}

	nextBtn.addEventListener("click", function () {
		currentIndex = (currentIndex + 1) % totalItems
		updateActiveItem(currentIndex)
	})

	prevBtn.addEventListener("click", function () {
		currentIndex = (currentIndex - 1 + totalItems) % totalItems
		updateActiveItem(currentIndex)
	})
}

const rotatingListWrapper = document.querySelector(".rotating-list")

if (rotatingListWrapper) {
	const rlImages = document.querySelector(".rl__images")
	const rlContent = document.querySelector(".rl__content")

	let listContent = gsap.utils.toArray(".rl__content li")
	let listImages = gsap.utils.toArray(".rl__images > img", rotatingListWrapper)

	gsap.matchMedia().add("(min-width: 992px)", () => {
		gsap.timeline({
			scrollTrigger: {
				trigger: rotatingListWrapper,
				start: "top top",
				end: "+=3000",
				pin: true,
				scrub: 8,
				onUpdate: self => {
					const progress = self.progress
					const totalItems = listContent.length
					const activeIndex = Math.floor(progress * totalItems)

					listContent.forEach((item, index) => {
						if (index === activeIndex) {
							if (!item.classList.contains("js-active")) {
								item.classList.add("js-active")
							}
						} else {
							item.classList.remove("js-active")
						}
					})

					listImages.forEach((img, index) => {
						if (index === activeIndex) {
							if (!img.classList.contains("js-active")) {
								img.classList.add("js-active")
							}
						} else {
							img.classList.remove("js-active")
						}
					})
				},
			},
		})
	})
}
