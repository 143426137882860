// ---------------------------------------------
// ======---------------------------------======
// Sticky header
// ======---------------------------------======
// ---------------------------------------------
if (typeof document.querySelector("#page-header") !== "undefined") {
	let lastScrollTopNav = 0
	const body = document.querySelector("body")
	const siteHeader = document.querySelector("#page-header")
	const siteAlert = document.querySelector(".site-alert")

	function setHeaderHeight() {
		const headerHeight = siteHeader.getBoundingClientRect().height
		const siteAlertHeight = siteAlert.getBoundingClientRect().height
		body.style.setProperty("--js-header-height", `${headerHeight}px`)
		body.style.setProperty("--js-alert-height", `${siteAlertHeight}px`)
	}
	setHeaderHeight()

	window.addEventListener(
		"scroll",
		function () {
			const st = window.scrollY || document.documentElement.scrollTop
			const headerHeight = siteHeader.getBoundingClientRect().height
			setHeaderHeight()

			if (body.getAttribute("data-animating") === "active") {
				return true
			} else if (lastScrollTopNav <= headerHeight || st > lastScrollTopNav) {
				if (
					body.getAttribute("data-sticky") === "header" &&
					body.getAttribute("data-menu-status") != "open"
				) {
					body.setAttribute("data-sticky", "header-out")

					setTimeout(() => {
						body.removeAttribute("data-sticky")
						body.classList.remove("sticky-header")
					}, 300)
				}
			} else {
				if (body.getAttribute("data-sticky") !== "header") {
					body.classList.add("sticky-header")
					body.setAttribute("data-sticky", "header-in")

					setTimeout(() => {
						if (lastScrollTopNav <= headerHeight) {
							body.removeAttribute("data-sticky")
							body.classList.add("sticky-header")
						} else {
							body.setAttribute("data-sticky", "header")
						}
					}, 300)
				}
			}
			lastScrollTopNav = st <= 0 ? 0 : st
		},
		false
	)
}
