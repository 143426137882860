//---------------------------------
//===---------------------------===
// App overview animation - GreenSock
//===---------------------------===
//---------------------------------
gsap.registerPlugin(ScrollTrigger)

const apWrapper = document.querySelector(".app-overview")

if (apWrapper) {
	const apControlsWrap = document.querySelector(".app-overview__controls")
	const apImagesWrap = document.querySelector(".app-overview__images")
	const apContent = document.querySelector(".app-overview__content")
	const apInner = document.querySelector(".app-overview__inner")
	let apControls = gsap.utils.toArray(
		".app-overview__controls li",
		apControlsWrap
	)
	let apPanels = gsap.utils.toArray(".app-overview__item", apContent)
	let apImages = gsap.utils.toArray(".app-overview__images > img", apWrapper)
	let apScroll = gsap.matchMedia().add("(min-width: 992px)", () => {
		gsap
			.timeline({
				onComplete: () => {},
				scrollTrigger: {
					trigger: apInner,
					start: "top top",
					end: "+=2000",
					pin: true,
					scrub: 2,
					onUpdate: self => {
						const x = self.progress
						const itemPos = x <= 0.3 ? 0 : x <= 0.6 ? 1 : x <= 0.9 ? 2 : 3

						if (
							!apControls[itemPos].classList.contains("js-active") &&
							!apControlsWrap.querySelector(".js-in")
						) {
							// Set animation states
							apControlsWrap.querySelector(".js-active").classList.add("js-out")
							apImagesWrap.querySelector(".js-active").classList.add("js-out")

							// delay in
							setTimeout(() => {
								apControls[itemPos].classList.add("js-in")
								apImages[itemPos].classList.add("js-in")
							}, 100)

							setTimeout(() => {
								apControlsWrap
									.querySelector(".js-active")
									.classList.remove("js-out", "js-active")
								apImagesWrap
									.querySelector(".js-active")
									.classList.remove("js-out", "js-active")
								apControls[itemPos].classList.add("js-active")
								apImages[itemPos].classList.add("js-active")
								apControls[itemPos].classList.remove("js-in")
								apImages[itemPos].classList.remove("js-in")
							}, 600)
						}

						const clearAnimate = setTimeout(() => {
							document.body.removeAttribute("data-animating")
						}, 500)

						if (x >= 1 || x <= 0) {
							clearAnimate
						} else {
							clearTimeout(clearAnimate)
							document.body.setAttribute("data-animating", "active")
						}
					},
				},
			})
			.to(apPanels, {
				yPercent: -100 * (apPanels.length - 1),
				ease: "none",
				duration: apPanels.length,
			})
	})
}
