// ---------------------------------------------
// ======---------------------------------======
// Compare list
// ======---------------------------------======
// ---------------------------------------------
const compareList = document.querySelector(".compare-list")
if (compareList) {
	const clBtns = document.querySelectorAll(".cl-controls button")

	function clearActive() {
		clBtns.forEach(btn => {
			const listId = btn.getAttribute("aria-controls")

			btn.setAttribute("aria-expanded", false)
			document.querySelector(`#${listId}`).classList.remove("active")
		})
	}

	clBtns.forEach(btn => {
		btn.addEventListener("click", event => {
			event.preventDefault()
			const listId = btn.getAttribute("aria-controls")

			// clear active
			clearActive()

			// set new active
			btn.setAttribute("aria-expanded", true)
			document.querySelector(`#${listId}`).classList.add("active")
		})
	})
}
